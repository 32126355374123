<template>
    <div>
        <v-progress-circular
            :rotate="270"
            :size="200"
            :width="30"
            :value="storageUsagePercentage"
            color="primary darken-2"
            v-if="hasStorageInfo"
        >
            <div class="text-center">
                {{ storageUsedBytes|formatBytes }} / {{ storageAvailableBytes|formatBytes }}<br />
                {{storageUsagePercentage.toFixed(2)}}%
            </div>
        </v-progress-circular>
        <v-alert color="warning" v-else>
            Geen opslag informatie gevonden.
        </v-alert>

    </div>
</template>

<script>
export default {
    name: "LocalStorageInfo",
    mounted() {
        this.loadStorageInfo()
    },
    data: () => ({
        hasStorageInfo: false,
        storageAvailableBytes: 5 * 1024 * 1024,
        storageUsedBytes: null

    }),
    computed: {
        storageUsagePercentage () {
            if (this.hasStorageInfo) {
                return (this.storageUsedBytes / this.storageAvailableBytes) * 100
            } else {
                return 0
            }
        }
    },
    methods: {
        loadStorageInfo() {
            this.hasStorageInfo = true;
            this.storageUsedBytes = window.localStorage.getItem('ticketcrew_app').length
        }
    },
    filters: {
        formatBytes(bytes, decimals = 2) {
            if (bytes === 0) return '0 Bytes';

            const k = 1024;
            const dm = decimals < 0 ? 0 : decimals;
            const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

            const i = Math.floor(Math.log(bytes) / Math.log(k));

            return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
        }
    },
}
</script>

<style scoped>

</style>
