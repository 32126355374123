<template>
    <v-container>
        <div class="text-h5">{{ appName }}</div>
        <div class="text-subtitle">{{ appVersion }}
            <v-chip x-small color="primary lighten-1" class="mb-1">{{ appFlavor }}</v-chip>
        </div>
        <v-row class="pt-4">
            <v-col cols="12" md="6" xl="4">
                <v-card class="mb-5">
                    <v-toolbar dark color="primary" elevation="0">
                        <v-icon
                            small
                            class="mr-3"
                        >
                            fal fa-code
                        </v-icon>
                        <v-toolbar-title>
                            API
                        </v-toolbar-title>
                    </v-toolbar>
                    <v-card-text>
                        <p>
                            Verbonden met API op:<br/>
                            <code>{{ apiUrl }}</code>
                        </p>
                        <v-alert dense color="warning" v-if="apiUrl !== 'https://api.ticketcrew.io/'" class="mb-0">
                            <strong>Let op:</strong> dit is niet de live-omgeving.
                        </v-alert>
                    </v-card-text>
                </v-card>

                <v-card>
                    <v-toolbar dark color="primary" elevation="0">
                        <v-icon
                            small
                            class="mr-3"
                        >
                            fal fa-phone-laptop
                        </v-icon>
                        <v-toolbar-title>
                            Device
                        </v-toolbar-title>
                    </v-toolbar>
                    <v-list two-line>
                        <v-list-item>
                            <v-list-item-icon>
                                <v-icon v-if="batteryLoading" color="primary">fal fa-battery-bolt</v-icon>
                                <v-icon v-else-if="!batteryLoading && batteryPercentage >= 90" color="success">fal fa-battery-full</v-icon>
                                <v-icon v-else-if="!batteryLoading && batteryPercentage >= 75" color="success lighten-2">fal fa-battery-three-quarters</v-icon>
                                <v-icon v-else-if="!batteryLoading && batteryPercentage >= 50" color="warning darken-1">fal fa-battery-half</v-icon>
                                <v-icon v-else-if="!batteryLoading && batteryPercentage >= 25" color="warning">fal fa-battery-quarter</v-icon>
                                <v-icon v-else color="error">fal fa-battery-empty</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content v-if="batteryStatus !== null">
                                <v-progress-linear :value="batteryPercentage" color="primary darken-2" class="mb-2"></v-progress-linear>
                                <v-list-item-title>Batterijstatus</v-list-item-title>
                                <v-list-item-subtitle>{{ batteryStatus }}</v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-content v-else>
                                <v-list-item-title>Batterijstatus</v-list-item-title>
                                <v-list-item-subtitle>Niet doorgegeven door browser</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                            <v-list-item-icon>
                                <v-icon v-if="isOnline" color="primary">fal fa-wifi</v-icon>
                                <v-icon v-if="isOffline" color="error">fal fa-wifi-slash</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>Netwerk</v-list-item-title>
                                <v-list-item-subtitle v-if="effectiveConnectionType">{{ effectiveConnectionType }} (<em>berekend</em>)</v-list-item-subtitle>
                                <v-list-item-subtitle>Status niet doorgegeven door browser</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-card>
            </v-col>
            <v-col cols="12" md="6" xl="4">
                <v-card class="mb-5">
                    <v-toolbar dark color="primary" elevation="0">
                        <v-icon
                            small
                            class="mr-3"
                        >
                            fal fa-user
                        </v-icon>
                        <v-toolbar-title>
                            User
                        </v-toolbar-title>
                    </v-toolbar>
                    <v-list two-line>
                        <v-list-item>
                            <v-list-item-content>
                                <v-list-item-title>Naam</v-list-item-title>
                                <v-list-item-subtitle>{{ user.name }}</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                            <v-list-item-content>
                                <v-list-item-title>Gebruikersnaam</v-list-item-title>
                                <v-list-item-subtitle>{{ user.username }}</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-card>

                <v-card>
                    <v-toolbar dark color="primary" elevation="0">
                        <v-icon
                            small
                            class="mr-3"
                        >
                            fal fa-cog
                        </v-icon>
                        <v-toolbar-title>
                            App Capabilities
                        </v-toolbar-title>
                    </v-toolbar>
                    <v-list>
                        <v-list-item two-line>
                            <v-list-item-action>
                                <v-checkbox :input-value="capabilities.webcam" readonly></v-checkbox>
                            </v-list-item-action>
                            <v-list-item-content>
                                <v-list-item-title>Webcam Scanner</v-list-item-title>
                                <v-list-item-subtitle>Barcodes scannen via webcam</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item two-line>
                            <v-list-item-action>
                                <v-checkbox :input-value="capabilities.audio" readonly></v-checkbox>
                            </v-list-item-action>
                            <v-list-item-content>
                                <v-list-item-title>Audio</v-list-item-title>
                                <v-list-item-subtitle>Geluiden afspelen via speaker</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-card>
            </v-col>
            <v-col cols="12" md="6">
                <v-card>
                    <v-toolbar dark color="primary" elevation="0">
                        <v-icon
                            small
                            class="mr-3"
                        >
                            fal fa-database
                        </v-icon>
                        <v-toolbar-title>
                            Storage (DB)
                        </v-toolbar-title>
                    </v-toolbar>
                    <v-card-text class="text-center">
                        <StorageInfo></StorageInfo>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="12" md="6">
                <v-card>
                    <v-toolbar dark color="primary" elevation="0">
                        <v-icon
                            small
                            class="mr-3"
                        >
                            fal fa-database
                        </v-icon>
                        <v-toolbar-title>
                            Storage (config)
                        </v-toolbar-title>
                    </v-toolbar>
                    <v-card-text class="text-center">
                        <LocalStorageInfo></LocalStorageInfo>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-btn @click="logout" class="me-2" color="warning">
                    <v-icon left>fal fa-sign-out</v-icon>
                    Uitloggen
                </v-btn>
                <v-btn @click="$store.dispatch('clearStorage')"  color="error">
                    <v-icon left>fal fa-trash</v-icon>
                    Lokale opslag opschonen
                </v-btn>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import interop from "@interop/index";
import StorageInfo from "@/components/about/StorageInfo";
import LocalStorageInfo from "../components/about/LocalStorageInfo";

export default {
    name: "About",
    components: {LocalStorageInfo, StorageInfo},
    mounted() {
        this.$store.dispatch('loadUserInfo');
        if (typeof navigator.getBattery === 'function') {
            navigator.getBattery().then(battery => {
                this.updateBatteryInfo(battery);
            })
        }

        if (navigator.connection) {
            this.effectiveConnectionType = navigator.connection.effectiveType;
            this.connectionType = navigator.connection.type;
        }
    },
    data: () => ({
        appName: process.env.VUE_APP_TITLE,
        appVersion: process.env.VUE_APP_VERSION,
        appFlavor: process.env.VUE_APP_FLAVOR,
        apiUrl: process.env.VUE_APP_API_URL,
        capabilities: interop,
        batteryStatus: null,
        batteryPercentage: 100,
        batteryLoading: false,
        effectiveConnectionType: null,
        connectionType: null,
    }),
    computed: {
        user() {
            return this.$store.state.auth.user
        }
    },
    methods: {
        updateBatteryInfo(battery) {
            this.batteryPercentage = Math.round(battery.level * 100)

            if (battery.charging) {
                this.batteryLoading = true
                this.batteryStatus = 'Laden (' + this.batteryPercentage + '%)';
            } else {
                this.batteryStatus = this.batteryPercentage + '%';
            }
        },
        logout() {
            if (window.confirm('Weet je zeker dat je wilt uitloggen?')) {
                this.$store.dispatch('logout').then(() => {
                    this.$router.push({name: 'login'});
                })
            }
        }
    }
}
</script>
